<mat-card class="cardMargin">
  <mat-card-title>{{template.name}}</mat-card-title>
  <mat-card-subtitle>
    <ng-template [ngIf]="template.languages.length > 1"><div class="indent">Languages: {{template.languages.join(", ")}}</div></ng-template>
    <ng-template [ngIf]="template.languages.length == 1"><div class="indent">Languages: {{template.languages.join(", ")}}</div></ng-template>
    <div class="indent" *ngIf="template.tools">
      Tools: {{template.tools.join(", ")}}
    </div>
    <div class="indent" *ngIf="template.focuses">
      Tags: {{template.focuses.join(", ")}}
    </div>
  </mat-card-subtitle>
  <mat-card-content>
    {{template.description}}
    <div *ngIf="template.links">
      <a *ngFor="let link of template.links" href="{{link.linkText}}">{{link.displayText}} <br></a>
    </div>

    <div *ngIf="template.image">
      <img *ngFor="let i of template.image" src={{i}} width="98%">
    </div>

  </mat-card-content>
  <mat-card-footer>
  </mat-card-footer>
</mat-card>