import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ProjectEntryComponent } from '../project-entry/project-entry.component';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { SceneService } from '../screen/scene.service';


@Component({
  selector: 'app-projects-view',
  templateUrl: './projects-view.component.html',
  styleUrls: ['./projects-view.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('1s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    ),
    trigger(
      'inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, height: 0 }),
            animate('0.5s ease-out',
              style({ opacity: 1, height: "100%" }))
          ]
        )
      ]
    )
  ]
})
export class ProjectsViewComponent implements OnInit {

  projectsDesc: ProjectDescriptor[] = [];
  projects: ProjectEntryComponent[] = [];
  projectsHeld: ProjectEntryComponent[] = [];

  filtLang: string = null;
  filtTool: string = null;
  filtFocus: string = null;
  filterUniverse: ProjectDetails = new ProjectDetails();

  constructor(private client: HttpClient, private scene: SceneService) { }

  ngOnInit(): void {
    window.onresize = () => { this.formatProjects() };
    console.log("aa")
    this.scene.setColour([0.0,0.4,0.4], 1000)

    this.client.get<ProjectDescriptor[]>("assets/projectList.json").toPromise().then(
      projects => {
        this.projectsDesc = projects;
        Promise.all(
          this.projectsDesc.map<Promise<void>>((elm, i, arr) => {return this.requestProject(elm)})
        ).then( v => {
          this.updateProjectList()
          this.showProjects()
        })
      }
    )
  }

  requestProject(desc: ProjectDescriptor): Promise<void> {
    return this.client.get<ProjectEntryComponent>("assets/proj/" + desc.file).toPromise().then(
      project => {
        if (project.languages)
          project.languages.sort();
        if (project.tools)
          project.tools.sort();
        if (project.focuses)
          project.focuses.sort();
        project.name = desc.name;
        project.priority = desc.priority;
        this.projectsHeld.push(project)
      }
    )
  }

  formatProjects() {
    let elm = document.getElementById("projectPreamble")
    if (elm !== undefined) {
      var height = elm.clientHeight;
      document.getElementById("projectContent").style.height = (window.innerHeight - height).toString() + "px";
    }
  }

  updateProjectList() {
    this.projects = [];
    for (let proj of this.projectsHeld) {
      if (
        (this.filtLang == null || proj.languages.includes(this.filtLang))
        && (this.filtTool == null || (proj.tools != null && proj.tools.includes(this.filtTool)))
        && (this.filtFocus == null || (proj.focuses != null && proj.focuses.includes(this.filtFocus)))
      ) {
        this.projects.push(proj);
      }
    }
  }

  showProjects() {
    this.projects = this.projectsHeld;
    this.projects.sort((p1, p2) => p1.priority - p2.priority)
    this.filterUniverse = new ProjectDetails();
    for (let proj of this.projectsHeld) {
      if (proj.languages)
        for (let lang of proj.languages)
          this.filterUniverse.languages.push(lang);
      if (proj.tools)
        for (let tool of proj.tools)
          this.filterUniverse.tools.push(tool);
      if (proj.focuses)
        for (let focus of proj.focuses)
          this.filterUniverse.focuses.push(focus);
    }

    this.filterUniverse.languages = this.filterUniverse.languages.sort().filter(onlyUnique);
    this.filterUniverse.tools = this.filterUniverse.tools.sort().filter(onlyUnique);
    this.filterUniverse.focuses = this.filterUniverse.focuses.sort().filter(onlyUnique);
  }

  filterLanguage(filterLang: MatSelectChange) {
    this.filtLang = filterLang.value;
    this.updateProjectList();
  }

  filterTool(filterTool: MatSelectChange) {
    this.filtTool = filterTool.value;
    this.updateProjectList();
  }

  filterFocus(filterFocus: MatSelectChange) {
    this.filtFocus = filterFocus.value;
    this.updateProjectList();
  }
}

class ProjectDetails {
  languages: string[] = [];
  tools: string[] = [];
  focuses: string[] = [];
}

interface ProjectDescriptor {
  name: string;
  file: string;
  priority: number;
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
