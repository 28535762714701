<div class="screen">

  <body>
    <app-screen></app-screen>
  </body>
</div>
<div class="overlay">
  <div>
    <router-outlet></router-outlet>
  <div>
</div>