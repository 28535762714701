import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss']
})
export class MenuButtonComponent implements OnInit {
  @Input() text:string;
  @Output() click: EventEmitter<any> = new EventEmitter();;
  @Input() isShort: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  doCallback() {
    this.click.emit();
  }
}
