<div class="glassWrap" [@inAnimation]>
  <div style="margin-left: 1ch; padding: 0%;">
		<a routerLink=""><mat-icon style="display: inline-block; margin: 0.5ch; top: 0.15ch; position: relative; color: azure;">arrow_back</mat-icon></a>
		<h1 style="display: inline-block;">Contact Information</h1>
		<mat-icon style="display: inline-block; padding-left: 0.5ch; top: 0.2ch; position: relative;">message</mat-icon>
	</div>
  <mat-card class="cardMargin">
    <mat-card-title>LinkedIn</mat-card-title>
    <mat-card-content>
      <button mat-icon-button style="position: absolute; right: 10px; bottom: calc(50% - 20px);"
        (click)="this.openLink('https://www.linkedin.com/in/ben-j-c/')">
        <mat-icon>open_in_new</mat-icon>
      </button>
      <div style="width: calc(100% - 50px);">
        This is my preferred method of contact. All my messages are sent to my email, so I should be able to get back to
        you promptly.
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="cardMargin">
    <mat-card-title>GitHub</mat-card-title>
    <mat-card-content>
      <button mat-icon-button style="position: absolute; right: 10px; bottom: calc(50% - 20px);"
        (click)="this.openLink('https://github.com/ben-j-c')">
        <mat-icon>open_in_new</mat-icon>
      </button>
      <div style="width: calc(100% - 50px);">
        This is where I keep all my public projects. Some of those listed in my projects section are private or just
        simply not uploaded.
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="cardMargin">
    <mat-card-title>YouTube</mat-card-title>
    <mat-card-content>
      <button mat-icon-button style="position: absolute; right: 10px; bottom: calc(50% - 20px);" aria-te
        (click)="this.openLink('https://www.youtube.com/channel/UCXpbyreYIoE3M1yHok62axw')">
        <mat-icon>open_in_new</mat-icon>
      </button>
      <div style="width: calc(100% - 50px);">
        This is where I upload videos of some of my projects. Most of the videos are short (10 seconds to 2 minutes)
        demonstrating functionality or progress on some specific project.
      </div>
    </mat-card-content>
  </mat-card>
</div>