import { Component, OnInit } from '@angular/core';
import * as MV from 'src/MV.js'
import * as geo from 'src/objects'
import { SceneService } from './scene.service';

@Component({
  selector: 'app-screen',
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.scss']
})
export class ScreenComponent implements OnInit {
  program: WebGLProgram;
  gl: WebGLRenderingContext;
  viewMatrix: any;
  modelMatrix: any;
  modelViewMatrix: any;
  projectionMatrix: any;
  normalMatrix: any;
  MS:any = [];

  modelViewMatrixLoc: WebGLUniformLocation;
  projectionMatrixLoc: WebGLUniformLocation;
  normalMatrixLoc: WebGLUniformLocation;

  constructor(private scene: SceneService) { }

  ngOnInit(): void {
    let canvas: HTMLCanvasElement = document.querySelector("#glCanvas");
    let gl = canvas.getContext("webgl2");
    this.gl = gl;
    gl.getExtension("GL_EXT_frag_depth");

    canvas.width = this.width;
    canvas.height = this.height;
    this.program = this.initShaders(gl, "assets/vertex.glsl", "assets/fragment.glsl");
    gl.useProgram(this.program);
    this.modelViewMatrixLoc = gl.getUniformLocation(this.program, "modelViewMatrix");
    this.projectionMatrixLoc = gl.getUniformLocation(this.program, "projectionMatrix");
    this.normalMatrixLoc = gl.getUniformLocation(this.program, "normalMatrix");
    this.modelMatrix = MV.mat4();
    this.viewMatrix = MV.lookAt(
      MV.vec3(0, 0, 10), //eye
      MV.vec3(0, 0, 0), //at
      MV.vec3(0, 1, 0), //up
    );
    this.projectionMatrix = MV.perspective(60, canvas.width / canvas.height, 0.5, 1000);
    this.setAllMatrices();

    geo.setGL(gl);
    //geo.Cube.init(this.program);
    //geo.Sphere.init(36, this.program);
    //geo.Surf.init(this.program, 100,100, 500);
    geo.PointSurf.init(this.program, 60 ,40, 80);
    geo.MeshSurf.init(this.program, 60, 40, 80);

    window.requestAnimationFrame((now) => { this.render(now) });
  }

  onResize(event): void {
  }

  render(now): void {
    let canvas: HTMLCanvasElement = document.querySelector("#glCanvas");
    let ctx = document.querySelector("#header");
    let gl = canvas.getContext("webgl2");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    gl.uniform1f(gl.getUniformLocation(this.program, "time"), now);
    let c = this.scene.curColour
    gl.uniform3f(gl.getUniformLocation(this.program, "colourFinal"), c[0], c[1], c[2]);
    gl.viewport(0, 0, canvas.width, canvas.height);
    gl.clearColor(0.1, 0.1, 0.12, 1.0);
    gl.enable(gl.DEPTH_TEST);
    gl.clear( gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    gl.lineWidth(3);

    this.viewMatrix = MV.lookAt(
      MV.vec3(0,5,10),//MV.vec3(10*Math.cos(now/2000), 5, 10*Math.sin(now/2000)), //eye
      MV.vec3(0, 0, 0), //at
      MV.vec3(0, 1, 0), //up
    );
    this.projectionMatrix = MV.perspective(60, canvas.width / canvas.height, 0.5, 1000);

    this.gPush()
    //this.gRotate(now/200, 0, 1, 0);
    this.setAllMatrices();
    geo.PointSurf.draw();
    geo.MeshSurf.draw();
    this.gPop()

    window.requestAnimationFrame((now) => { this.render(now) });
  }

  get width() {
    return window.innerWidth;
  }

  get height() {
    return window.innerHeight;
  }

  loadFileAJAX(name): string {
    var xhr = new XMLHttpRequest(),
      okStatus = document.location.protocol === "file:" ? 0 : 200;
    xhr.open('GET', name, false);
    xhr.send(null);
    return xhr.status == okStatus ? xhr.responseText : null;
  }

  getShader(gl: WebGLRenderingContext, shaderName: string, type: number): WebGLShader {
    var shader = gl.createShader(type),
      shaderScript = this.loadFileAJAX(shaderName);
    if (!shaderScript) {
      alert("Could not find shader source: " + shaderName);
    }
    gl.shaderSource(shader, shaderScript);
    gl.compileShader(shader);

    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      alert(gl.getShaderInfoLog(shader));
      return null;
    }
    return shader;
  }

  initShaders(gl: WebGL2RenderingContext, vShaderName: string, fShaderName: string): WebGLProgram {
    var vertexShader = this.getShader(gl, vShaderName, gl.VERTEX_SHADER),
      fragmentShader = this.getShader(gl, fShaderName, gl.FRAGMENT_SHADER),
      program = gl.createProgram();

    gl.attachShader(program, vertexShader);
    gl.attachShader(program, fragmentShader);
    gl.linkProgram(program);

    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
      alert("Could not initialise shaders");
      return null;
    }

    return program;
  }

  setMV() {
    this.modelViewMatrix = MV.mult(this.viewMatrix, this.modelMatrix);
    this.gl.uniformMatrix4fv(this.modelViewMatrixLoc, false, MV.flatten(this.modelViewMatrix));
    this.normalMatrix = MV.inverseTranspose(this.modelViewMatrix);
    this.gl.uniformMatrix4fv(this.normalMatrixLoc, false, MV.flatten(this.normalMatrix));
  }

  setAllMatrices() {
    this.gl.uniformMatrix4fv(this.projectionMatrixLoc, false, MV.flatten(this.projectionMatrix));
    this.setMV();
  }

  // Post multiples the modelview matrix with a translation matrix
  // and replaces the modelview matrix with the result
  gTranslate(x, y, z) {
    this.modelMatrix = MV.mult(this.modelMatrix, MV.translate([x, y, z]));
  }

  // Post multiples the modelview matrix with a rotation matrix
  // and replaces the modelview matrix with the result
  gRotate(theta, x, y, z) {
    this.modelMatrix = MV.mult(this.modelMatrix, MV.rotate(theta, [x, y, z]));
  }

  // Post multiples the modelview matrix with a scaling matrix
  // and replaces the modelview matrix with the result
  gScale(sx, sy, sz) {
    this.modelMatrix = MV.mult(this.modelMatrix, MV.scale(sx, sy, sz));
  }

  // Pops MS and stores the result as the current modelMatrix
  gPop() {
    this.modelMatrix = this.MS.pop();
  }

  // pushes the current modelMatrix in the stack MS
  gPush() {
    this.MS.push(this.modelMatrix);
  }

}