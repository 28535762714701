import * as MV from './MV.js'

export var gl: WebGLRenderingContext;

export function setGL(newGL:WebGLRenderingContext) {
    gl = newGL;
}

export function setAttribPointers(obj) {
    gl.bindBuffer(gl.ARRAY_BUFFER, obj.nBuffer);
    gl.vertexAttribPointer(obj.vNormal, 3, gl.FLOAT, false, 0, 0);

    gl.bindBuffer(gl.ARRAY_BUFFER, obj.pBuffer);
    gl.vertexAttribPointer(obj.vPosition, 4, gl.FLOAT, false, 0, 0);

    gl.bindBuffer(gl.ARRAY_BUFFER, obj.cBuffer);
    gl.vertexAttribPointer(obj.vColor, 4, gl.FLOAT, false, 0, 0);

    gl.bindBuffer(gl.ARRAY_BUFFER, obj.tBuffer);
    gl.vertexAttribPointer(obj.vTexCoord, 2, gl.FLOAT, false, 0, 0);

}


export function setBuffers(obj, program) {
    obj.nBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, obj.nBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, MV.flatten(obj.normalsArray), gl.STATIC_DRAW);

    obj.vNormal = gl.getAttribLocation(program, "vNormal");
    gl.vertexAttribPointer(obj.vNormal, 3, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(obj.vNormal);

    obj.pBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, obj.pBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, MV.flatten(obj.pointsArray), gl.STATIC_DRAW);

    obj.vPosition = gl.getAttribLocation(program, "vPosition");
    gl.vertexAttribPointer(obj.vPosition, 4, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(obj.vPosition);

    obj.cBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, obj.cBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, MV.flatten(obj.colorsArray), gl.STATIC_DRAW);

    obj.vColor = gl.getAttribLocation(program, "vColor");
    gl.vertexAttribPointer(obj.vColor, 4, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(obj.vColor);

    obj.tBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, obj.tBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, MV.flatten(obj.texCoordsArray), gl.STATIC_DRAW);

    obj.vTexCoord = gl.getAttribLocation(program, "vTexCoord");
    gl.vertexAttribPointer(obj.vTexCoord, 2, gl.FLOAT, false, 0, 0);
    gl.enableVertexAttribArray(obj.vTexCoord);

}


//
//
//
//
//
//
//
//
//

class SphereSub_imp {
    numTimesToSubdivide: number;
    index: number = 4;
    pointsArray: MV.vec4[] = [];
    normalsArray: MV.vec3[] = [];
    triangle(a: number, b: number, c: number) {
        var na = MV.vec3(a[0], a[1], a[2]);
        var nb = MV.vec3(b[0], b[1], b[2]);
        var nc = MV.vec3(c[0], c[1], c[2]);

        this.normalsArray.push(na);
        this.normalsArray.push(nb);
        this.normalsArray.push(nc);

        this.pointsArray.push(a);
        this.pointsArray.push(b);
        this.pointsArray.push(c);

        this.index += 3;
    }

    divideTriangle = function (a, b, c, count) {
        if (count > 0) {

            var ab = MV.mix(a, b, 0.5);
            var ac = MV.mix(a, c, 0.5);
            var bc = MV.mix(b, c, 0.5);

            ab = MV.normalize(ab, true);
            ac = MV.normalize(ac, true);
            bc = MV.normalize(bc, true);

            this.divideTriangle(a, ab, ac, count - 1);
            this.divideTriangle(ab, b, bc, count - 1);
            this.divideTriangle(bc, c, ac, count - 1);
            this.divideTriangle(ab, bc, ac, count - 1);
        }
        else {
            this.triangle(a, b, c);
        }
    }



    tetrahedron(a, b, c, d, n) {
        this.divideTriangle(a, b, c, n);
        this.divideTriangle(d, c, b, n);
        this.divideTriangle(a, d, b, n);
        this.divideTriangle(a, c, d, n);
    }
    init(program) {
        var va = MV.vec4(0.0, 0.0, -1.0, 1);
        var vb = MV.vec4(0.0, 0.942809, 0.333333, 1);
        var vc = MV.vec4(-0.816497, -0.471405, 0.333333, 1);
        var vd = MV.vec4(0.816497, -0.471405, 0.333333, 1);

        this.tetrahedron(va, vb, vc, vd, this.numTimesToSubdivide);
        setBuffers(this, program);
    }


    draw = function () {

        setAttribPointers(this);
        gl.drawArrays(gl.TRIANGLES, 0, this.index);

    }
}
export var SphereSub: SphereSub_imp = new SphereSub_imp();

//--------------- CUBE --------------




// cube ///////////////////////////////////////////////////////////////////////
//    v6----- v5
//   /|      /|
//  v1------v0|
//  | |     | |
//  | |v7---|-|v4
//  |/      |/
//  v2------v3

// vertex coords array for glDrawArrays() =====================================
// A cube has 6 sides and each side has 2 triangles, therefore, a cube consists
// of 36 vertices (6 sides * 2 tris * 3 vertices = 36 vertices). And, each
// vertex is 3 components (x,y,z) of floats, therefore, the size of vertex
// array is 108 floats (36 * 3 = 108).
var vertices1 = [1, 1, 1, -1, 1, 1, -1, -1, 1,      // v0-v1-v2 (front)
    -1, -1, 1, 1, -1, 1, 1, 1, 1,      // v2-v3-v0

    1, 1, 1, 1, -1, 1, 1, -1, -1,      // v0-v3-v4 (right)
    1, -1, -1, 1, 1, -1, 1, 1, 1,      // v4-v5-v0

    1, 1, 1, 1, 1, -1, -1, 1, -1,      // v0-v5-v6 (top)
    -1, 1, -1, -1, 1, 1, 1, 1, 1,      // v6-v1-v0

    -1, 1, 1, -1, 1, -1, -1, -1, -1,      // v1-v6-v7 (left)
    -1, -1, -1, -1, -1, 1, -1, 1, 1,      // v7-v2-v1

    -1, -1, -1, 1, -1, -1, 1, -1, 1,      // v7-v4-v3 (bottom)
    1, -1, 1, -1, -1, 1, -1, -1, -1,      // v3-v2-v7

    1, -1, -1, -1, -1, -1, -1, 1, -1,      // v4-v7-v6 (back)
    -1, 1, -1, 1, 1, -1, 1, -1, -1];    // v6-v5-v4

// normal array
var normals1 = [0, 0, 1, 0, 0, 1, 0, 0, 1,      // v0-v1-v2 (front)
    0, 0, 1, 0, 0, 1, 0, 0, 1,      // v2-v3-v0

    1, 0, 0, 1, 0, 0, 1, 0, 0,      // v0-v3-v4 (right)
    1, 0, 0, 1, 0, 0, 1, 0, 0,      // v4-v5-v0

    0, 1, 0, 0, 1, 0, 0, 1, 0,      // v0-v5-v6 (top)
    0, 1, 0, 0, 1, 0, 0, 1, 0,      // v6-v1-v0

    -1, 0, 0, -1, 0, 0, -1, 0, 0,      // v1-v6-v7 (left)
    -1, 0, 0, -1, 0, 0, -1, 0, 0,      // v7-v2-v1

    0, -1, 0, 0, -1, 0, 0, -1, 0,      // v7-v4-v3 (bottom)
    0, -1, 0, 0, -1, 0, 0, -1, 0,      // v3-v2-v7

    0, 0, -1, 0, 0, -1, 0, 0, -1,      // v4-v7-v6 (back)
    0, 0, -1, 0, 0, -1, 0, 0, -1];    // v6-v5-v4

// color array
var colors1 = [1, 0, 0, 1, 0, 0, 1, 0, 0,      // v0-v1-v2 (front)
    1, 0, 0, 1, 0, 0, 1, 0, 0,      // v2-v3-v0

    0, 0, 0, 0, 0, 0, 0, 0, 0,      // v0-v3-v4 (right)
    0, 0, 0, 0, 0, 0, 0, 0, 0,      // v4-v5-v0

    0, 1, 0, 0, 1, 0, 0, 1, 0,      // v0-v5-v6 (top)
    0, 1, 0, 0, 1, 0, 0, 1, 0,      // v6-v1-v0

    1, 1, 0, 1, 1, 0, 1, 1, 0,      // v1-v6-v7 (left)
    1, 1, 0, 1, 1, 0, 1, 1, 0,      // v7-v2-v1

    0, 1, 1, 0, 1, 1, 0, 1, 1,      // v7-v4-v3 (bottom)
    0, 1, 1, 0, 1, 1, 0, 1, 1,      // v3-v2-v7

    0, 0, 1, 0, 0, 1, 0, 0, 1,      // v4-v7-v6 (back)
    0, 0, 1, 0, 0, 1, 0, 0, 1];    // v6-v5-v4

var cubeTexCoord = [1, 1, 0, 1, 0, 0,      // v0-v1-v2 (front)
    0, 0, 1, 0, 1, 1,      // v2-v3-v0

    0, 1, 0, 0, 1, 0,      // v0-v3-v4 (right)
    1, 0, 1, 1, 0, 1,      // v4-v5-v0

    1, 0, 1, 1, 0, 1,      // v0-v5-v6 (top)
    0, 1, 0, 0, 1, 0,      // v6-v1-v0

    1, 1, 0, 1, 0, 0,     // v1-v6-v7 (left)
    0, 0, 1, 0, 1, 1,      // v7-v2-v1

    0, 1, 0, 0, 1, 0,      // v7-v4-v3 (bottom)
    1, 0, 1, 1, 0, 1,  // v3-v2-v7

    0, 0, 1, 0, 1, 1,     // v4-v7-v6 (back)
    1, 1, 0, 1, 0, 0];    // v6-v5-v4

class Cube_imp {
    numVertices: number = 36;
    pointsArray: MV.vec4[] = [];
    normalsArray: MV.vec3[] = [];
    colorsArray: MV.vec4[] = [];
    texCoordsArray: number[] = [];
    vertices: MV.vec4[] = [];

    init(program) {
        var count = 0;
        var texCount = 0;
        for (var i = 0; i < vertices1.length; i++) {
            this.pointsArray.push(MV.vec4(vertices1[count], vertices1[count + 1], vertices1[count + 2], 1.0));
            this.normalsArray.push(MV.vec3(normals1[count], normals1[count + 1], normals1[count + 2]));
            this.colorsArray.push(MV.vec4(colors1[count], colors1[count + 1], colors1[count + 2], 1.0));

            this.texCoordsArray.push(cubeTexCoord[texCount], cubeTexCoord[texCount + 1]);

            count = count + 3;
            texCount = texCount + 2;
        }
        setBuffers(this, program);
    }


    draw() {

        setAttribPointers(this);
        gl.frontFace(gl.CCW);
        gl.drawArrays(gl.TRIANGLES, 0, 36);
    }
}
export var Cube: Cube_imp = new Cube_imp();
Cube.vertices = [
    MV.vec4(-0.5, -0.5, 0.5, 1.0),
    MV.vec4(-0.5, 0.5, 0.5, 1.0),
    MV.vec4(0.5, 0.5, 0.5, 1.0),
    MV.vec4(0.5, -0.5, 0.5, 1.0),
    MV.vec4(-0.5, -0.5, -0.5, 1.0),
    MV.vec4(-0.5, 0.5, -0.5, 1.0),
    MV.vec4(0.5, 0.5, -0.5, 1.0),
    MV.vec4(0.5, -0.5, -0.5, 1.0)
];

//-------------- Quad --------------
// A quad of size 2x2 centered around the origin.
// Made up of two triangels drawn as fan

class Quad_imp {
    numVertices: number = 4;
    pointsArray: MV.vec4[] = [];
    normalsArray: MV.vec3[] = [];
    colorsArray: MV.vec4[] = [];
    texCoordsArray: number[] = [];

    init(program) {
        this.pointsArray = [
            MV.vec4(-0.5, -0.5, 0.0, 1.0),
            MV.vec4(-0.5, 0.5, 0.0, 1.0),
            MV.vec4(0.5, 0.5, 0.0, 1.0),
            MV.vec4(0.5, -0.5, 0.0, 1.0)
        ];
        this.normalsArray = [MV.vec3(0, 0, 1),
        MV.vec3(0, 0, 1),
        MV.vec3(0, 0, 1),
        MV.vec3(0, 0, 1)
        ];

        this.colorsArray = [MV.vec4(1, 0, 0, 1),
        MV.vec4(1, 0, 0, 1),
        MV.vec4(1, 0, 0, 1),
        MV.vec4(1, 0, 0, 1)
        ];
        this.texCoordsArray = [MV.vec2(0, 0), MV.vec2(0, 1), MV.vec2(1, 1), MV.vec2(1, 0)];

        setBuffers(this, program);
    }

    draw() {
        setAttribPointers(this); // Must call the vertexAttribPointer every time
        // because it sets the size of the buffer,
        //behind the scene
        gl.drawArrays(gl.TRIANGLE_FAN, 0, 4);

    }
}

export var Quad: Quad_imp = new Quad_imp();

//-------------- Surface --------------
// A surface of size -width/2 to width/2 centered around the origin. By ben

/*

Order of drawn triangles.

┌───────────────────┐
| \    6  | \    8  |
|   \     |   \     |
|  5  \   |  7  \   |
|       \ |       \ |
├─────────┼─────────|
| \    2  | \    4  |
|   \     |   \     |
|  1  \   |  3  \   |
|       \ |       \ |
└───────────────────┘

x
^
|_____> z

*/

class Surf_imp {
    numVertices: number = 4;
    n: number = 4;
    pointsArray: MV.vec4[] = [];
    normalsArray: MV.vec3[] = [];
    colorsArray: MV.vec4[] = [];
    texCoordsArray: MV.vec2[] = [];

    init(program:WebGLProgram, width:number, height:number, count:number) {
        this.n = count * count * 6;
        this.pointsArray = [];
        this.normalsArray = [];
        this.colorsArray = [];
        this.texCoordsArray = [];

        for (var i = 0; i < count; i++) {
            var row1 = i * height / count - height / 2;
            var row2 = (i + 1) * height / count - height / 2;

            for (var j = 0; j < count; j++) {
                var col1 = j * width / count - width / 2;
                var col2 = (j + 1) * width / count - width / 2;
                this.pointsArray.push(MV.vec4(row1, 0.0, col1, 1.0));
                this.pointsArray.push(MV.vec4(row1, 0.0, col2, 1.0));
                this.pointsArray.push(MV.vec4(row2, 0.0, col1, 1.0));
                this.texCoordsArray.push(MV.vec2(0, 0));
                this.texCoordsArray.push(MV.vec2(0, 1));
                this.texCoordsArray.push(MV.vec2(1, 0));
                this.colorsArray.push(MV.vec4(1, 0, 0, 1));
                this.colorsArray.push(MV.vec4(1, 0, 0, 1));
                this.colorsArray.push(MV.vec4(1, 0, 0, 1));
                this.normalsArray.push(MV.vec3(0.0, 1.0, 0.0));
                this.normalsArray.push(MV.vec3(0.0, 1.0, 0.0));
                this.normalsArray.push(MV.vec3(0.0, 1.0, 0.0));

                this.pointsArray.push(MV.vec4(row1, 0.0, col2, 1.0));
                this.pointsArray.push(MV.vec4(row2, 0.0, col2, 1.0));
                this.pointsArray.push(MV.vec4(row2, 0.0, col1, 1.0));
                this.texCoordsArray.push(MV.vec2(0, 1));
                this.texCoordsArray.push(MV.vec2(1, 1));
                this.texCoordsArray.push(MV.vec2(1, 0));
                this.colorsArray.push(MV.vec4(1, 0, 0, 1));
                this.colorsArray.push(MV.vec4(1, 0, 0, 1));
                this.colorsArray.push(MV.vec4(1, 0, 0, 1));
                this.normalsArray.push(MV.vec3(0.0, 1.0, 0.0));
                this.normalsArray.push(MV.vec3(0.0, 1.0, 0.0));
                this.normalsArray.push(MV.vec3(0.0, 1.0, 0.0));
            }
        }

        //console.log(this.pointsArray.toString());
        setBuffers(this, program);
    }

    draw() {

        setAttribPointers(this); // Must call the vertexAttribPointer every time
        // because it sets the size of the buffer,
        //behind the scene
        gl.drawArrays(gl.TRIANGLES, 0, this.n);

    }
}

export var Surf: Surf_imp = new Surf_imp();
//------------- MeshSurf -------------


class MeshSurf_imp {
    n: number = 4;
    pointsArray: MV.vec4[] = [];
    normalsArray: MV.vec3[] = [];
    colorsArray: MV.vec4[] = [];
    texCoordsArray: MV.vec2[] = [];

    init(program:WebGLProgram, width:number, height:number, count:number) {
        this.n = 4*(count)*(count+1);
        for (var i = 0; i < count; i++) {
            var row1 = i * height / count - height / 2;
            var row2 = (i + 1) * height / count - height / 2;

            for (var j = 0; j <= count; j++) {
                var col1 = j * width / count - width / 2;
                this.pointsArray.push(MV.vec4(col1, 0, row1, 1));
                this.normalsArray.push(MV.vec3(0,1,0));
                this.colorsArray.push(MV.vec4(1,1,1,1));
                this.texCoordsArray.push(MV.vec2(j/count,i/count));

                this.pointsArray.push(MV.vec4(col1, 0, row2, 1));
                this.normalsArray.push(MV.vec3(0,1,0));
                this.colorsArray.push(MV.vec4(1,1,1,1));
                this.texCoordsArray.push(MV.vec2(j/count,(i+1)/count));
            }
        }

        for (var i = 0; i <= count; i++) {
            var row1 = i * height / count - height / 2;
            var row2 = (i + 1) * height / count - height / 2;

            for (var j = 0; j < count; j++) {
                var col1 = j * width / count - width / 2;
                var col2 = (j + 1) * width / count - width / 2;
                this.pointsArray.push(MV.vec4(col1, 0, row1, 1));
                this.normalsArray.push(MV.vec3(0,1,0));
                this.colorsArray.push(MV.vec4(1,1,1,1));
                this.texCoordsArray.push(MV.vec2(j/count,i/count));

                this.pointsArray.push(MV.vec4(col2, 0, row1, 1));
                this.normalsArray.push(MV.vec3(0,1,0));
                this.colorsArray.push(MV.vec4(1,1,1,1));
                this.texCoordsArray.push(MV.vec2((j+1)/count,i/count));
            }
        }
        setBuffers(this, program);
    }

    draw() {
        setAttribPointers(this);
        gl.drawArrays(gl.LINES, 0, this.n);
    }
}

export var MeshSurf:MeshSurf_imp = new MeshSurf_imp();

class PointSurf_imp {
    n: number = 1;
    pointsArray: MV.vec4[] = [];
    normalsArray: MV.vec3[] = [];
    colorsArray: MV.vec4[] = [];
    texCoordsArray: MV.vec2[] = [];

    init(program:WebGLProgram, width:number, height:number, count:number) {
        this.n = (count+1)*(count+1);
        for (var i = 0; i <= count; i++) {
            var row1 = i * height / count - height / 2;

            for (var j = 0; j <= count; j++) {
                var col1 = j * width / count - width / 2;
                this.pointsArray.push(MV.vec4(col1, 0, row1, 1));
                this.normalsArray.push(MV.vec3(0,1,0));
                this.colorsArray.push(MV.vec4(1,1,1,1));
                this.texCoordsArray.push(MV.vec2(j/count,i/count));
            }
        }
        setBuffers(this, program);
    }

    draw() {
        setAttribPointers(this);
        gl.drawArrays(gl.POINTS, 0, this.n);
    }
}
export var PointSurf:PointSurf_imp = new PointSurf_imp();


//-------------- Circle --------------


class Circle_imp {
    n: number;
    pointsArray: MV.vec4[] = [];
    normalsArray: MV.vec3[] = [];
    colorsArray: MV.vec4[] = [];
    texCoordsArray: MV.vec2[] = [];

    init (program, n) {
        this.n = n;
        this.pointsArray = [];
        this.normalsArray = [];
        this.colorsArray = [];
        this.texCoordsArray = [];

        this.pointsArray.push(MV.vec3(0, 0, 0));
        this.normalsArray.push(MV.vec3(0, 1, 0));
        this.colorsArray.push(MV.vec4(1, 0, 0, 1));
        this.texCoordsArray.push(MV.vec2(0.5, 0.5));
        var inc = Math.PI * 2 / n;
        for (var i = 0; i <= n; i++) {
            var theta = i * inc;
            var x = Math.cos(theta);
            var y = Math.sin(theta);
            //console.log("theta:" + theta + " x " + x + " y " + y);
            this.pointsArray.push(MV.vec4(x, 0, y, 1));
            this.pointsArray.push(MV.vec3(0, 1, 0));
            this.colorsArray.push(MV.vec4(1, 0, 0, 1));
            this.texCoordsArray.push(MV.vec2(0.5 * Math.cos(theta) + 0.5, 0.5 * Math.sin(theta) + 0.5));

        }

        setBuffers(this, program);
    }



    draw() {

        setAttribPointers(this);
        gl.drawArrays(gl.TRIANGLE_FAN, 0, this.n + 1);

    }

}

export var Circle: Circle_imp = new Circle_imp();

//-------------- Cylinder --------------

class Cylinder_imp {
    n: number;
    pointsArray: MV.vec4[] = [];
    normalsArray: MV.vec3[] = [];
    colorsArray: MV.vec4[] = [];
    texCoordsArray: MV.vec2[] = [];

    getVertex (u, v) {
        var vd: {
            position:any;
            normal:any;
            colour:any;
            texCoord:any;
        } = {position:null, normal:null, colour:null, texCoord:null};
        vd.position = MV.vec4(0.5 * Math.cos(u * 2 * Math.PI), 0.5 * Math.sin(u * 2 * Math.PI), v - 0.5, 1.0);
        vd.normal = MV.vec3(Math.cos(u * 2 * Math.PI), Math.sin(u * 2 * Math.PI), 0.0);
        vd.colour = MV.vec4(u, v, 0.0, 1.0);
        vd.texCoord = MV.vec2(u, v);

        return vd;
    }

    init = function (n, program) {
        this.n = n;
        if (this.n < 1) return;

        var du = 1.0 / this.n;
        var dv = du;
        // do it by quads made up of two triangles
        for (var u = 0; u < 1.0; u += du) {
            for (var v = 0; v < 1.0; v += dv) {
                //cerr << "----------------------------\n" ;
                //cerr << "(" << u << "," << v << ")" << endl ;
                //cerr << "(" << u+du << "," << v << ")" << endl ;
                //cerr << "(" << u+du << "," << v+dv << ")" << endl ;
                //cerr << "(" << u << "," << v+dv << ")" << endl ;

                // make them into triangles
                var vd1 = this.getVertex(u, v);
                var vd2 = this.getVertex(u + du, v);
                var vd3 = this.getVertex(u + du, v + dv);
                var vd4 = this.getVertex(u, v + dv);

                // Triangle one

                AddInAttribArrays(this, vd1);
                AddInAttribArrays(this, vd2);
                AddInAttribArrays(this, vd3);


                // Triangle two
                AddInAttribArrays(this, vd3);
                AddInAttribArrays(this, vd4);
                AddInAttribArrays(this, vd1);
            }
        }

        setBuffers(this, program);
    }

    draw () {

        gl.frontFace(gl.CCW);
        //gl.enable(gl.CULL_FACE) ;
        //gl.disable(gl.CULL_FACE) ;

        setAttribPointers(this);
        gl.drawArrays(gl.TRIANGLES, 0, this.n * this.n * 6);

    }
}

export var Clylinder:Cylinder_imp = new Cylinder_imp();

//------------- CONE --------------------------------

class Cone_imp {
    n: number;
    pointsArray: MV.vec4[] = [];
    normalsArray: MV.vec3[] = [];
    colorsArray: MV.vec4[] = [];
    texCoordsArray: MV.vec2[] = [];

    getVertex(u, v) {
        var radius = 1.0 - v;
        var vd: {
            position:any;
            normal:any;
            colour:any;
            texCoord:any;
        } = {position:null, normal:null, colour:null, texCoord:null};
        vd.position = MV.vec4(radius * Math.cos(u * 2 * Math.PI), radius * Math.sin(u * 2 * Math.PI), v - 0.5, 1.0);
        var ntemp = MV.vec3(Math.cos(u * 2 * Math.PI), Math.sin(u * 2 * Math.PI), 1.0);
        vd.normal = MV.normalize(ntemp);
        vd.colour = MV.vec4(u, v, 0.0, 1.0);

        vd.texCoord = MV.vec2(u, v);

        return vd;
    }


    init(n, program) {
        this.n = n;
        if (this.n < 1) return;

        var du = 1.0 / this.n;
        var dv = du;
        // do it by quads made up of two triangles
        for (var u = 0; u < 1.0; u += du) {
            for (var v = 0; v < 1.0; v += dv) {
                //cerr << "----------------------------\n" ;
                //cerr << "(" << u << "," << v << ")" << endl ;
                //cerr << "(" << u+du << "," << v << ")" << endl ;
                //cerr << "(" << u+du << "," << v+dv << ")" << endl ;
                //cerr << "(" << u << "," << v+dv << ")" << endl ;

                // make them into triangles
                var vd1 = this.getVertex(u, v);
                var vd2 = this.getVertex(u + du, v);
                var vd3 = this.getVertex(u + du, v + dv);
                var vd4 = this.getVertex(u, v + dv);

                // Triangle one

                AddInAttribArrays(this, vd1);
                AddInAttribArrays(this, vd2);
                AddInAttribArrays(this, vd3);


                // Triangle two
                AddInAttribArrays(this, vd3);
                AddInAttribArrays(this, vd4);
                AddInAttribArrays(this, vd1);

            }
        }

        setBuffers(this, program);
    }

    draw() {

        gl.frontFace(gl.CCW);
        //gl.enable(gl.CULL_FACE) ;
        //gl.disable(gl.CULL_FACE) ;

        setAttribPointers(this);
        gl.drawArrays(gl.TRIANGLES, 0, this.n * this.n * 6);

    }

}

export var Cone:Cone_imp = new Cone_imp();

//------------ sphere ------------------------

class Sphere_imp {
    n: number;
    pointsArray: MV.vec4[] = [];
    normalsArray: MV.vec3[] = [];
    colorsArray: MV.vec4[] = [];
    texCoordsArray: MV.vec2[] = [];

    getVertex(uu, vv) {
        var vd: {
            position:any;
            normal:any;
            colour:any;
            texCoord:any;
        } = {position:null, normal:null, colour:null, texCoord:null};
        var u = uu * Math.PI;
        var v = vv * 2 * Math.PI;

        vd.position = MV.vec4(Math.cos(u) * Math.sin(v),
            Math.sin(u) * Math.sin(v),
            Math.cos(v),
            1.0);
        vd.normal = MV.vec3(vd.position[0], vd.position[1], vd.position[2]);

        vd.colour = MV.vec4(uu, vv, 0.0, 1.0);

        vd.texCoord = MV.vec2(uu, vv);

        return vd;
    }

    init(n, program) {

        this.n = n;
        if (this.n < 1) return;

        var du = 1.0 / this.n;
        var dv = du;
        // do it by quads made up of two triangles
        for (var u = 0; u < 1.0; u += du) {
            for (var v = 0; v < 1.0; v += dv) {
                //cerr << "----------------------------\n" ;
                //cerr << "(" << u << "," << v << ")" << endl ;
                //cerr << "(" << u+du << "," << v << ")" << endl ;
                //cerr << "(" << u+du << "," << v+dv << ")" << endl ;
                //cerr << "(" << u << "," << v+dv << ")" << endl ;

                // make them into triangles
                var vd1 = this.getVertex(u, v);
                var vd2 = this.getVertex(u + du, v);
                var vd3 = this.getVertex(u + du, v + dv);
                var vd4 = this.getVertex(u, v + dv);

                // Triangle one
                if (!flip(vd1, vd2, vd3)) {
                    AddInAttribArrays(this, vd1)
                    AddInAttribArrays(this, vd2);
                    AddInAttribArrays(this, vd3);

                } else {
                    AddInAttribArrays(this, vd1)
                    AddInAttribArrays(this, vd3);
                    AddInAttribArrays(this, vd2);

                }


                // Triangle two
                if (!flip(vd3, vd4, vd1)) {
                    AddInAttribArrays(this, vd3)
                    AddInAttribArrays(this, vd4);
                    AddInAttribArrays(this, vd1);

                } else {
                    AddInAttribArrays(this, vd3)
                    AddInAttribArrays(this, vd1);
                    AddInAttribArrays(this, vd4);
                }
            }
        }

        setBuffers(this, program);
    }

    draw() {

        gl.frontFace(gl.CW);
        //gl.enable(gl.CULL_FACE) ;
        //gl.disable(gl.CULL_FACE) ;

        setAttribPointers(this);
        gl.drawArrays(gl.TRIANGLES, 0, this.n * this.n * 6);

    }
}

export var Sphere:Sphere_imp = new Sphere_imp();

export function AddInAttribArrays(obj, v) {
    obj.pointsArray.push(v.position);
    obj.normalsArray.push(v.normal);
    obj.colorsArray.push(v.colour);
    obj.texCoordsArray.push(v.texCoord);

}

export function flip(vd1, vd2, vd3) {
    // compute average normal

    var an = MV.scalev(1.0 / 3.0, MV.add(vd1.normal, MV.add(vd2.normal, vd3.normal)));

    // compute from triangle
    var va = MV.subtract(vd2.normal, vd1.normal);
    var vb = MV.subtract(vd3.normal, vd1.normal);
    var tn = MV.cross(vb, va);
    if (MV.dot(an, tn) < 0.0) return true;

    return false;
}

