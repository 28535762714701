import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SceneService {

  public curColour: number[] = [0.4,0,0]
  public curPos: number[] = [0,0,10]
  public curLookAt: number[] = [0,0,0]
  public curUp: number[] = [0,1,0]

  private nowLast = 0.0;
  private tColour = 0;
  private tPos = 0;
  private tLookAt = 0;
  private tUp = 0;
  private tarColour: number[] = [0.4,0,0]
  private tarPos: number[] = [0,0,10]
  private tarLookAt: number[] = [0,0,0]
  private tarUp: number[] = [0,1,0]

  constructor() { 
    this.anim(0)
  }

  anim(now) {
    let dt = now - this.nowLast
    if (this.tColour > 0) {
      this.curColour[0] = interp(dt, this.tColour, this.curColour[0], this.tarColour[0])
      this.curColour[1] = interp(dt, this.tColour, this.curColour[1], this.tarColour[1])
      this.curColour[2] = interp(dt, this.tColour, this.curColour[2], this.tarColour[2])
      this.tColour = Math.max(this.tColour - dt, 0);
    }

    this.nowLast = now
    window.requestAnimationFrame((now) => {
      this.anim(now)
    })
  }

  setColour(rgb: number[], dt: number) {
    this.tarColour = rgb
    this.tColour = dt
  }

  setPos(xyz: number[], dt: number) {
    this.tarPos = xyz
    this.tPos = dt
  }

  setLookAt(xyz: number[], dt: number) {
    this.tarLookAt = xyz
    this.tPos = dt
  }

  setUp(xyz: number[], dt: number) {
    this.tarUp = xyz
    this.tLookAt = dt
  }
}

function interp(dt: number, t: number, cur: number, tar: number): number {
  if (dt > t) {
    cur = tar
  } else {
    cur = (tar - cur) * dt / t + cur
  }
  return cur
}