<div class="glassWrap" [@inAnimation]>
	<div style="margin-left: 1ch; padding: 0%;">
		<a routerLink=""><mat-icon style="display: inline-block; margin: 0.5ch; top: 0.15ch; position: relative; color: azure;">arrow_back</mat-icon></a>
		<h1 style="display: inline-block;">Education</h1>
		<mat-icon style="display: inline-block; padding-left: 0.5ch; top: 0.2ch; position: relative;">school</mat-icon>
	</div>
	<div class="indent">
		<mat-card class="cardMargin">
			<mat-card-title>MSc. in Applied Computing (MScAC)</mat-card-title>
			<mat-card-subtitle>University of Toronto, December 2023</mat-card-subtitle>
			<mat-card-content>
				<ul>
					<li>Special Topics in Computer Systems: Visual and Mobile Computing Systems</li>
					<li>Special Topics in Software Engineering: Designing Web Scale Infrastructure</li>
					<li>Introduction to the Theory of Distributed Computing</li>
					<li>Topics in Storage Systems: Machine Learning to Build Better Systems</li>
				</ul>
			</mat-card-content>
		</mat-card>
		<mat-card class="cardMargin">
			<mat-card-title>B.Eng., Specialized Honours Computer Engineering</mat-card-title>
			<mat-card-subtitle>York University, May 2020</mat-card-subtitle>
			<mat-card-content>
				<mat-card-content>
					<ul>
						<li>Computer Vision</li>
						<li>Machine Learning</li>
						<li>Graphics</li>
						<li>Signals & Systems</li>
						<li>FPGA Development</li>
						<li>Computer Architecture</li>
						<li>Embedded Systems</li>
					</ul>
				</mat-card-content>
			</mat-card-content>
		</mat-card>
		<mat-card class="cardMargin">
			<mat-card-title>Undergraduage Awards</mat-card-title>
			<mat-card-content>
				<ul>
					<li>First Class Standing With Distinction: cumulative grade point average is 8.00+ (highest honours)
					</li>
					<li>Dean's Honour Roll (2016, 2017, 2020)</li>
					<li>Member of Chair's Honour Roll for exemplary perfomance in Mathematics (2016)</li>
				</ul>
			</mat-card-content>
		</mat-card>
	</div>
</div>