<div class="glassWrap" [@inAnimation]>
	<div style="min-height: fit-content" id="projectPreamble">
		<div style="margin-left: 1ch; padding: 0%;">
			<a routerLink=""><mat-icon style="display: inline-block; margin: 0.5ch; top: 0.15ch; position: relative; color: azure;">arrow_back</mat-icon></a>
			<h1 style="display: inline-block;">Projects</h1>
			<mat-icon style="display: inline-block; padding-left: 0.5ch; top: 0.2ch; position: relative;">code</mat-icon>
		</div>
		<mat-card class="cardMargin">
			<mat-card-content>
				<mat-card-title>Filters</mat-card-title>
				<div class="center">
					<mat-form-field color="accent" appearance="fill" class="cardMargin">
						<mat-label>Language</mat-label>
						<mat-select (selectionChange)="this.filterLanguage($event)">
							<mat-option>No filter</mat-option>
							<mat-option *ngFor="let lang of this.filterUniverse.languages" value="{{lang}}">{{lang}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field color="accent" appearance="fill" class="cardMargin">
						<mat-label>Tool</mat-label>
						<mat-select (selectionChange)="this.filterTool($event)">
							<mat-option>No filter</mat-option>
							<mat-option *ngFor="let tool of this.filterUniverse.tools" value="{{tool}}">{{tool}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field color="accent" appearance="fill" class="cardMargin">
						<mat-label>Tag</mat-label>
						<mat-select (selectionChange)="this.filterFocus($event)">
							<mat-option>No filter</mat-option>
							<mat-option *ngFor="let focus of this.filterUniverse.focuses" value="{{focus}}">{{focus}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
	<div class="scrollZone" id="projectContent" [@inAnimation] (@inAnimation.done)="this.formatProjects()">
		<app-project-entry *ngFor="let proj of projects" [template]="proj"></app-project-entry>
		<br>
	</div>
</div>