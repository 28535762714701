import { Component, OnInit, Input } from '@angular/core';


export interface Link {
  displayText:string,
  linkText:string
}

@Component({
  selector: 'app-project-entry',
  templateUrl: './project-entry.component.html',
  styleUrls: ['./project-entry.component.scss']
})
export class ProjectEntryComponent implements OnInit {
  @Input() name:string;
  @Input() showDesc:boolean = false;
  @Input() showTools:boolean = false;
  @Input() showFocus:boolean = false;
  @Input() template:ProjectEntryComponent;

  languages:string[];
  tools:string[];
  description:string;
  points:string[];
  focuses:string[];
  youtube:string[];
  image:string[];
  links:Link[];
  priority: number;

  constructor() { }

  ngOnInit(): void {
  }
}
