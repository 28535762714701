import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactViewComponent } from './contact-view/contact-view.component';
import { EducationViewComponent } from './education-view/education-view.component';
import { MainViewComponent } from './main-view/main-view.component';
import { ProjectsViewComponent } from './projects-view/projects-view.component';

const routes: Routes = [
  {path: 'projects', component: ProjectsViewComponent},
  {path: 'education', component: EducationViewComponent},
  {path: 'contact', component: ContactViewComponent},
  {path: '', component: MainViewComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
