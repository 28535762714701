import { Component, OnInit } from '@angular/core';

export const displaysSet = {
  TITLE: "title",
  PROFILE: "profile",
  PROJECTS: "projects",
  EDUCATION: "education",
  LINKS: "links",
  CONTACT: "contact"
};

type displayMode = "title" | "projects" | "education" | "links" | "contact" | "profile";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  displayMode: displayMode = "title";

  get width() {
    return window.innerWidth;
  }

  get height() {
    return window.innerHeight;
  }

  checkMode(displayMode: displayMode): boolean {
    return this.displayMode === displayMode;
  }
}
