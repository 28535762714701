import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { SceneService } from '../screen/scene.service';

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('1s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    ),
    trigger(
      'inAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0, height: 0 }),
            animate('0.5s ease-out',
              style({ opacity: 1, height: "100%" }))
          ]
        )
      ]
    )
  ]
})
export class MainViewComponent implements OnInit {

  constructor(private router: Router, private scene: SceneService) { }

  ngOnInit(): void {
    this.scene.setColour([0.4,0.0,0.0], 1000)
  }

  showProjects() {

  }

  showEducation() {

  }

}
